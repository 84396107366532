<template>
  <div className="animated">
    <i class="icon-menu mr-1"></i>Applicants<hr />
      <v-server-table 
        class="bg-light p-2 vue-table-table"
        name="dataTableApplicantList"
        :columns="columns"
        :url="url"
        :options="options"
        :useVuex="true"
        :theme="theme"
        id="dataTable"
        :preserveState="preserveState"
      >
        <template slot="fullName" slot-scope="props">
          {{ props.row.fullName }}
        </template>
        <template slot="datetimeSubmitted" slot-scope="props">
          {{ $moment(props.row.datetimeSubmitted).format('MM/DD/YY h:mma') }}
        </template>
        <template slot="user" slot-scope="props">
          {{ props.row.user?"Yes":"No" }}
        </template>
      </v-server-table>
  </div>
</template>

<script>
import Vue from 'vue'
import { HTTP } from '@/services/http-common'
import { constants } from '@/shared/constants'
import { ServerTable, Event } from 'vue-tables-2-premium'
import { mapGetters, mapActions } from 'vuex'

Vue.use(ServerTable)

export default {
  name: 'ApplicantList',
  components: {
    Event
  },
  props: {
    userRole: {
      type: String,
      default: null
    }
  },
  data: function () {
    return {
      columns: ['fullName', 'datetimeSubmitted', 'user', 'phone', 'email', 'data.sport', 'data.type'],
      options: {
        perPage: 30,
        perPageValues: [30,60,90],
        requestFunction (data) {
          return HTTP.get(this.url, {
              params: data
          }).catch(function (e) {
              this.dispatch('error', e)
          }.bind(this))
        },
        requestAdapter (data) {
          let query = {
            sort: data.orderBy ? data.orderBy : 'datetimeSubmitted',
            direction: data.ascending ? 'asc' : 'desc',
            itemsPerPage: data.limit ? data.limit : constants.LIST_DEFAULT_ITEMS_PER_PAGE,
            page: data.page ? data.page : '1'
          }
          if (data.query !='') {
            query.fullName = data.query
          }
          if (data.orderBy) {
            let param = 'order[' + data.orderBy + ']'
            query[param] = data.ascending ? 'asc' : 'desc'
          } else {
            query['order[datetimeSubmitted]'] = 'desc'
          }
          return query
        },
        responseAdapter ({data}) {
          return {
            data: data['hydra:member'],
            count: data['hydra:totalItems']
          }
        },
        headings: {
          fullName: 'Name',
          user: 'Member',
          datetimeSubmitted: 'Date',
          'data.type': 'Type',
          'data.sport': 'Sport',
          actions: 'Actions'
        },
        sortable: ['id', 'fullName', 'datetimeSubmitted'],
        filterable: ['id', 'fullName', 'data'],
        sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
        pagination: {
          chunk: 5,
          edge: false,
          nav: 'scroll'
        }
      },
      preserveState: true,
      useVuex: true,
      theme: 'bootstrap4',
      template: 'default',
      viewUid: null
    }
  },
  computed: {
    ...mapGetters([
      // 'applicants',
      'business'
    ]),
    url () {
      return this.currentBusiness.id ? '/applicants?business=' + this.currentBusiness.id : '/applicants'
    },
    // async applicantdata () {
    //   let applicants = []
    //   await HTTP.get(this.url, {
    //       params: this.options.requestAdapter({})
    //   }).then(resp => {
    //     applicants = resp.data["hydra:member"]
    //   })
    //   return applicants
    // }
  },
  methods: {
    ...mapActions([
      'getApplicants'
    ]),
    doGetApplicants(){
      let params = {}
      if (this.currentBusiness.id !== null) {
        params.business = this.currentBusiness.id
      }
      this.getApplicants(params)
    }
  },
  filters: {
    formatRole: function (value) {
      if (!value) return ''
      value = value.substring(4).toLowerCase()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  }
};
</script>
